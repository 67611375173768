.loginForm input{
    width: 20rem;
    border: 2px solid #5e5f60;
    border-radius: 10px;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
}

.loginForm input :focus{
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none;
}


.login-btn {
    width: 100%;
    background-color:#252526;
    color: white;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 2px ;
}
.google-login-btn{
    width: 100%;
    background-color:#f8f7f7;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 2px ;
    border: 2px solid rgb(232, 232, 232);
}
.google-login-btn:hover{
  border: 2px solid darkgray;
 border-radius:5px ;
 background-color:#f8f7f7;
 box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
 font-weight: bolder;
}
.google-logo{
    width: 2rem;
}
.google-brand{
    width: 5rem;
}
.login-btn:hover {
    border: 2px solid black;
    border-radius:5px ;
    background-color:#f8f7f7;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
    font-weight: bolder;
 
}

.login-btn:focus{
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}
.loginPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  

}

.login-main-section{
   
    padding: 3rem;
   
    border-radius: 2rem;
    width: max-content;
    height: max-content;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}

.formContainer{
display: flex;
justify-content: center;


}

.Login-to-NoteX{
    text-transform: uppercase;
    font-weight: bolder;
    font-size:xx-large;
    text-align: center;
    
}

.logo-container{
display: flex;
justify-content: center;
}

.login-logo{
width: 10rem;
height: auto;
}

.signUp-link
{
    color: black;
    font-weight: bold;
  
}

.signUp-link:hover
{
    color: darkslategray;
}

@media (max-width: 767px) {

    .login-main-section{
   
    padding: 0;
    border-radius: 0;
    width: auto;
    height: auto;
    box-shadow: none;
    
}


.loginForm input{
    width: 19rem;
    
}
}