.userName{
    font-size: xx-large;
    font-weight: bolder;
   
}

.emptyNotes-div{
   
position: absolute;
top: 30%;
font-weight: bolder;
font-style: italic;
font-size:xx-large ;
color: lightgray;
}

.utility-icons{
    display: flex;
    justify-content: space-between;
    padding: 1% 2% 0 2%
}

.search-bar{
    outline: none;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
}
.search-bar:hover{
    outline: none;
    border: none;
    border-bottom: 2px solid black;
   box-shadow: none;
}

.search-bar:focus{
    outline: none;
    border: none;
    border-bottom: 2px solid black;
   box-shadow: none;
}

.search-btn{
    font-weight: bold;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    margin-left: -14%;
}

.search-btn:hover{
    outline: none;
    border: none;
    font-weight: bolder;
   
}

.search-form{
  width: 82%;
    }

.search-form:hover{
outline: none;
border: none;
box-shadow: none;
}

.search-form:focus{
    outline: none;
    border: none;
    box-shadow: none;
    }
.search-btn:focus{
    border: none;  
    font-weight: bolder;
  
}

@media (max-width: 1023px) {

    .utility-icons{
      display: block;
    }
}