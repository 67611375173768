.description-feild{
    width: 100%;
    outline: none;
    resize: none;
}

.description-feild ::-webkit-scrollbar{
   width: 0px;
}

.add-note-btn{
   border: none;
   background-color: transparent;
  /* position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 10; */
 
}


.modal-content{
  outline: none;
  border: none;
}
.modal-title{
    font-weight: bolder;
    text-transform: uppercase;
}

.modal-header{
  border-color: rgb(222, 208, 135);   
    /* background: #232526;  
    background: -webkit-linear-gradient(to right,#232526, #414345, #232526);  
    background: linear-gradient(to right,#232526, #414345, #232526); 
    outline: none;
color: whitesmoke; */
color: rgb(161, 133, 93);
background-color: rgb(250, 250, 183);;
}
.modal-body{
     color: rgb(161, 133, 93);
  background-color: lightgoldenrodyellow;
}
.modal-body label{
  font-weight: bold;
  text-transform: uppercase;
}
.modal-body input:focus{
    border: 2px solid  rgb(161, 133, 93);
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  .title-feild input{
    min-width: 85%;
    max-width: max-content;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  .tag-feild input{
    min-width: 70%;
    max-width: max-content;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
  .description-feild textarea{
    height: 12rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    white-space: pre-wrap;
  }  
.modal-body textarea:focus{
    border: 2px solid  rgb(161, 133, 93);
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }  

.modal-footer{
 border-color: rgb(222, 208, 135);   
background-color: rgb(250, 250, 183);;
}
.cancel-btn{
border: none;
width: 24%;
background-color: rgb(161, 133, 93);
color: white;
text-transform: uppercase;
font-weight: bolder;
box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
border-radius: 5px;
}
.save-note-btn{
  border: none;
  width: 20%;
  text-transform:uppercase ;
  font-weight: bolder;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  color: rgb(161, 133, 93);
  background-color: lightgoldenrodyellow;
  border-radius: 5px;
}

.cancel-btn:hover{
  background-color: lightgoldenrodyellow;
  color: rgb(161, 133, 93);
  margin-bottom: 0;
  border: 2px solid burlywood;
  border-radius: 5px;
  }
.save-note-btn:hover{
    border: 2px solid burlywood;
    margin-bottom: 0;
    background-color: lightgoldenrodyellow;
  }
.close-btn{
    color: rgb(162, 146, 55); 
    background-color: transparent;
    border: none;
    font-size: larger;
    font-weight: bolder;
}