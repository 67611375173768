.footer-div{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10rem;   
    background-color: rgba(13, 13, 13, 0.903);
    width: 100vw;
    box-shadow: -5px -5px 10px;
   color: lightgray;
}

.footer-logo{
    width: 4rem;
}