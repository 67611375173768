.cancel-btn{
    border: none;
    width: 24%;
    background-color: rgb(161, 133, 93);
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 5px;
    }
    .update-note-btn{
      border: none;
      width: 20%;
      text-transform:uppercase ;
      font-weight: bolder;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      color: rgb(161, 133, 93);
      background-color: lightgoldenrodyellow;
      border-radius: 5px;
    }
    
    .cancel-btn:hover{
      background-color: lightgoldenrodyellow;
      color: rgb(161, 133, 93);
      margin-bottom: 0;
      border: 2px solid burlywood;
      border-radius: 5px;
      }
    .update-note-btn:hover{
        border: 2px solid burlywood;
        margin-bottom: 0;
        background-color: lightgoldenrodyellow;
      }
    .close-btn{
        color: rgb(162, 146, 55); 
        background-color: transparent;
        border: none;
        font-size: larger;
        font-weight: bolder;
    }

    .description-feild textarea{
        height: 12rem;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        white-space: pre-wrap;
      } 